.main {
  &__section {
    max-height: calc(100vh - 10rem);
    overflow: auto;
  }

  &__container {
    max-width: $max-width;
    margin: 0 auto;
    padding: $default-padding;
    box-sizing: content-box;
  }

  &__subnav {
    padding: 1rem;
  }

  &__content {
    padding: 1rem;
  }

  &__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    padding: 0 0 2rem;
  }

  &__header {
    background-color: nth($bluescale, 2);
    padding: 2rem 1rem;

    &__title {
      color: $white;
      font-size: 2rem;
      // text-transform: uppercase;
    }
  }

  &__warning {
    padding: 0 0 0.5rem 0;
    line-height: 1rem;

    &__container {
      text-align: center;
      padding: 1rem 0 2rem;
    }
  }

  &__subtitle {
    padding: 0 0.625rem 0.625rem 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    // border-bottom: 1px solid nth($bluescale, 2);
    margin: 0 0 1rem 0;
  }
}
