@import '~variables';

.main__nav {
  width: $nav-width;
  min-width: $nav-width;
  background: nth($grayscale, 4);
  height: 100vh;
}

.main__nav {
  &__header {
    background: nth($bluescale, 2);
    position: relative;
    padding: 0 0 3rem 0;
    margin: 0 0 2rem 0;
  }

  &__company {
    display: block;
    color: $white;
    font-size: 3rem;
    text-align: center;
  }

  &__user {
    width: 4rem;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__list {
    overflow: hidden;
    padding-top: 1.1rem;
  }

  &__item {
    padding: 0 0 0.5rem 0;
  }

  &__link {
    display: block;
    text-align: left;
    color: $white;
    overflow: hidden;
    margin: 0 auto;
    text-transform: capitalize;

    &:before {
      display: inline-block;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      text-align: center;
      line-height: 2rem;
      font-size: 1.1rem;
      margin: 0 10px;
    }

    &:hover {
      &:before {
        background: nth($grayscale, 1);
      }
    }

    &.active {
      &:before {
        background: nth($bluescale, 1);
        color: $white;
      }
      color: $white;
    }
  }
}
