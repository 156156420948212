@import '~variables';

.app {
  &__container {
    display: flex;
  }

  &__content {
    flex-grow: 2;
    height: 100vh;

    .view__container {
      height: calc(100vh - $head-height);
      width: calc(100vw - $nav-width);
    }
  }
}


// @import '~variables';

// .app {
//   &__container {
//     &.login {
//       display: flex;

//       .app__container {
//         flex-grow: 2;
//       }
//     }

//     &.content {

//     }
//   }

//   &__content {
//     width: calc(100vw - $nav-width);
//     height: 100vh;

//     .view__container {
//       height: calc(100vh - $head-height);
//     }
//   }
// }


