.btn {
  font-size: 1rem;
  line-height: 2rem;
  background-color: nth($grayscale, 1);
  padding: 0 10px;
  text-align: center;
  display: inline-block;
  color: $white;
  text-transform: uppercase;
  font-family: $openSans-l;
  border-radius: 4px;
  white-space: nowrap;

  &:hover {
    background: nth($bluescale, 1);
  }

  &.invert {
    background: nth($bluescale, 1);

    &:hover {
      background-color: nth($grayscale, 1);
    }
  }
}

/* Ripple magic */
.ripple{
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.ripple:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}
