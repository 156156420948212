@import '~variables';

.clear {
  &:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
  }
}

.regular {
  font-family: $openSans;
  font-weight: 400;
}

.light {
  font-family: $openSans-l;
  font-weight: 300;
}

.bold,
b {
  font-family: $openSans-b;
  font-weight: 700;
}

.block {
  display: block;
}

button {
  border: none;
  cursor: pointer;
  background-color: inherit;
}

img {
  // max-width: calc(100% - 2rem);
}

.hide {
  display: none;
}

.center {
  text-align: center;
}

.card {
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border: 1px solid nth($grayscale, 3);
}

// FORM ELEMENTS
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="tel"],
input[type="datetime-local"],
input[type="date"],
textarea,
select,
.select {
  border: 1px solid nth($grayscale, 3);
  height: 2.875rem;
  width: 100%;
  display: block;
  font-size: 0.875rem;
  line-height: 2.875rem;
  padding: 0 0.625rem;
  border-radius: 4px;

  &:focus {
    border-color: nth($grayscale, 1);

    &:after {
      border-left: 1px solid nth($grayscale, 1);
    }
  }

  &.error {
    border: 1px solid nth($redscale, 1);
  }
}

textarea {
  line-height: 1.3rem;
  min-height: 7.1875rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="datetime-local"],
input[type="date"] {
  position: relative;

  &:after {
    content: '\e918';
    font-family: $icons;
    background: $white;
    text-align: center;
    line-height: 2.875rem;
    position: absolute;
    color: nth($grayscale, 3);
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 100%;
    border-left:  1px solid nth($grayscale, 3);
  }

  &::-webkit-calendar-picker-indicator {
    width: 1.7rem;
    height: 100%;
    position: relative;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid black;
    cursor: pointer;
    opacity: 0;
  }

  &:hover,
  &:focus {
    &:after {
      color: $black;
    }
  }
}

.select {
  background: $white;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5rem;
    height: 100%;
    border-left:  1px solid nth($grayscale, 3);
    z-index: 0;
  }

  &:before {
    content: "\e909";
    font-family: $icons;
    border: 0;
    color: nth($grayscale, 3);
    text-align: center;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    &:before {
      color: $black;
    }
  }
}

select {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  position: absolute;
  padding: 0 3rem 0 0.625rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

textarea {
  max-width: 100%;
  min-width: 100%;
}

.error__message {
  font-size: 0.7rem;
  color: red;
  line-height: 1rem;
}
