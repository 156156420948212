$nav-width: 10rem;
$head-height: 4rem;
$max-width: 73.125rem;
$default-padding: 1rem;
$max-container-width: 1170px;

// KANBAN SPECIFICS
$cards-width: 20rem;

// FONTS
$openSans: 'OpenSans-Regular', sans-serif;
$openSans-b: 'OpenSans-Bold', sans-serif;
$openSans-l: 'OpenSans-Light', sans-serif;
$icons: 'icons';

// FONT-WEIGHT
$bold: 700;
$regular: 400;
$light: 300;

// COLORS
$white: #ffffff;
$black: #000000;
$greenscale: #1ada67;
$yellowscale: #f5a623;
$grayscale: #1d2127, #2d323d, #cecece, #2C2C2C, #686868, #F5F5F5;
$bluescale: #34aadc, #3C4253;
$redscale: #ff808b;
$theme: #ddeae0, #2DFEFE;
