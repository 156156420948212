@import '~variables';

.menu {
  &__list {
    padding: 0 0.5rem;
    font-size: 0.7rem;

    &.icons {
      max-width: 200px;
    }
  }

  &__item {
  }

  &__btn {
    display: block;
    color: nth($grayscale, 5);
    width: 100%;
    text-align: left;
    line-height: 2rem;
    padding: 0 0.5rem;
    transition: background 200ms linear;

    &:hover {
      background: lighten(nth($grayscale, 3), 10%);
    }

    &:before {
      padding-right: 0.4rem;
    }

    &__icon {
      line-height: 4rem;
      &:before {
        display: block;
        text-align: center;
        font-size: 3rem;
      }
    }
  }
}
