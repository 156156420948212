@import '~variables';

.form {
  overflow: hidden;

  &__title {
    text-transform: uppercase;
    font-size: 1.5rem;
    padding: 1.5rem 0;
  }

  &__label {
    display: block;
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0 0 0.675rem;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    &-col1 {
      .form__item {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &-col2 {
      .form__item {
        width: 50%;

        &:nth-child(odd){
          padding-left: 0;
        }

        &:nth-child(even){
          padding-right: 0;
        }
      }
    }

    &-col3 {
      .form__item {
        width: 33.33%;

        &:nth-child(3n+1) {
          padding-left: 0;
        }

        &:nth-child(3n+0) {
          padding-right: 0;
        }
      }
    }
  }

  &__item {
    padding: 0 0.9375rem 0.9375rem;

    &.padd-b-off {
      padding-bottom: 0;
    }
  }

  &__btn {
    line-height: 2.875rem;
    width: 100%;
  }

  &__errors {
    &__list {
      font-size: 0.8rem;
      padding: 1rem 0 0 0;
    }

    &__sublist {
      padding: 0.4rem 0 0;
    }

    &__item {
      position: relative;
      padding: 0.4rem 0 0 0.8rem;

      &:before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 0.625rem;
        left: 0;
        background: nth($grayscale, 5);
      }
    }

    &__subitem {
      padding: 0.4rem 0 0.4rem 0.8rem;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        position: absolute;
        top: 0.625rem;
        left: 0;
        background: nth($grayscale, 5);
      }
    }

    &__item.invalid,
    &__subitem.invalid {
      &:before {
        background-color: nth($redscale, 1);
      }
    }

    &__item.valid,
    &__subitem.valid {
      &:before {
        background-color: nth($greenscale, 1);
      }
    }
  }

  &__header {}

  &__title,
  &__copy {
    text-align: center;
  }

  &__title {

  }

  &__copy {
    line-height: 1.5rem;

    &.single {
      padding: 1rem 0 0 0;
      font-size: 0.75rem;
    }
  }

  &__link {
    cursor: pointer;
    color: nth($bluescale, 1);

    &:hover {
      color: nth($yellowscale, 1);
    }
  }
}
