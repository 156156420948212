@import '~variables';

.user {
  &__menu {
    &__container {
      padding-left: 10px;
    }
  }

  &__display {
    padding: 0.5rem 0.5rem 0;

    &.user__header {
      display: flex;
      float: right;
      padding-right: 0;
    }

    &.profile {
      display: flex;
      padding: 0;

      .user {
        &__name {
          color: $white;
          font-size: 2rem;
          line-height: 2rem;
          padding: 2.4rem 1rem 1rem;
        }

        &__image {
          width: 8rem;
          height: 8rem;

          &.default {
            &:before {
              width: 5rem;
              height: 5rem;
              text-align: center;
              line-height: 4.5rem;
              font-size: 4.5rem;
            }
          }
        }
      }
    }

    &.imageOnly {
      padding: 0 0.5rem 0;
    }

    &.inverted {
      .user__image {
        background-color: $white;

        &.default {
          &:before {
            color: nth($bluescale, 2);
          }
        }
      }
    }

    &.table__size {
      .user__image {
        width: 2rem;
        height: 2rem;

        &.default {
          &:before {
            width: 2rem;
            height: 2rem;
            font-size: 1.2rem;
            line-height: 1.9rem;
          }
        }
      }
    }
  }

  &__data {
    padding: 0.5625rem 0 0 0.625rem;
  }

  &__name {
    font-size: 0.8rem;
    padding-bottom: 0.3rem;
  }

  &__image {
    width: 3rem;
    height: 3rem;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-color: nth($bluescale, 2);
    position: relative;

    &.default {
      &:before {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        text-align: center;
        line-height: 2.3rem;
        font-size: 1.7rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
  }

  // roles
  &__roles {
    &__list {
      overflow: hidden;
    }

    &__item {
      float: left;
      padding-right: 4px;
      &:before {
        font-size: 0.8rem;
        color: nth($bluescale, 2);
      }
    }
  }
}

.pick__photo__btn {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(nth($bluescale, 2), 0.7);
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 1;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $white;
    transform: translate(-50%, -50%);
    font-size: 3rem;
  }
}
