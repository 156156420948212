@include font-face("OpenSans-Regular", "../../assets/fonts/openSans/OpenSans-Regular");
@include font-face("OpenSans-Bold", "../../assets/fonts/openSans/OpenSans-Bold");
@include font-face("OpenSans-Light", "../../assets/fonts/openSans/OpenSans-Light");

@font-face {
  font-family: "icons";
  src: url("../../assets/fonts/icons/lmcc.ttf?4z3mtf") format("truetype"),
    url("../../assets/fonts/icons/lmcc.woff?4z3mtf") format("woff"),
    url("../../assets/fonts/icons/lmcc.svg?4z3mtf#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bed-linen:before {
  content: "\e9e6";
}
.icon-Coffee-Break:before {
  content: "\e9e7";
}
.icon-Fast-Food:before {
  content: "\e9e8";
}
.icon-mission:before {
  content: "\e9e9";
}
.icon-mission2:before {
  content: "\e9ea";
}
.icon-purpose:before {
  content: "\e9eb";
}
.icon-view:before {
  content: "\e9ec";
}
.icon-menu7:before {
  content: "\e9e5";
}
.icon-inscription:before {
  content: "\e9ac";
}
.icon-diary:before {
  content: "\e9ad";
}
.icon-torch:before {
  content: "\e9ae";
}
.icon-axe:before {
  content: "\e9af";
}
.icon-route:before {
  content: "\e9b0";
}
.icon-adventure-2:before {
  content: "\e9b1";
}
.icon-forest:before {
  content: "\e9b2";
}
.icon-satellite:before {
  content: "\e9b3";
}
.icon-adventure-1:before {
  content: "\e9b4";
}
.icon-hiking:before {
  content: "\e9b5";
}
.icon-vehicle:before {
  content: "\e9b6";
}
.icon-carabiner:before {
  content: "\e9b7";
}
.icon-parachute:before {
  content: "\e9b8";
}
.icon-water-bottle:before {
  content: "\e9b9";
}
.icon-matches:before {
  content: "\e9ba";
}
.icon-cooking:before {
  content: "\e9bb";
}
.icon-dishes:before {
  content: "\e9bc";
}
.icon-backpack:before {
  content: "\e9bd";
}
.icon-walkie-talkie:before {
  content: "\e9be";
}
.icon-camera1:before {
  content: "\e9bf";
}
.icon-binoculars:before {
  content: "\e9c0";
}
.icon-zippo:before {
  content: "\e9c1";
}
.icon-bbq:before {
  content: "\e9c2";
}
.icon-map:before {
  content: "\e9c3";
}
.icon-fins:before {
  content: "\e9c4";
}
.icon-rope:before {
  content: "\e9c5";
}
.icon-fire:before {
  content: "\e9c6";
}
.icon-kayak:before {
  content: "\e9c7";
}
.icon-location-1:before {
  content: "\e9c8";
}
.icon-thermo:before {
  content: "\e9c9";
}
.icon-passport:before {
  content: "\e9ca";
}
.icon-hot-air-balloon:before {
  content: "\e9cb";
}
.icon-swiss-army-knife:before {
  content: "\e9cc";
}
.icon-location:before {
  content: "\e9cd";
}
.icon-medicine:before {
  content: "\e9ce";
}
.icon-knife:before {
  content: "\e9cf";
}
.icon-paw1:before {
  content: "\e9d0";
}
.icon-lamp:before {
  content: "\e9d1";
}
.icon-tent:before {
  content: "\e9d2";
}
.icon-weather:before {
  content: "\e9d3";
}
.icon-road-sign:before {
  content: "\e9d4";
}
.icon-fishing:before {
  content: "\e9d5";
}
.icon-adventure:before {
  content: "\e9d6";
}
.icon-mountain:before {
  content: "\e9d7";
}
.icon-wifi:before {
  content: "\e9d8";
}
.icon-boots:before {
  content: "\e9d9";
}
.icon-lifesaver:before {
  content: "\e9da";
}
.icon-clothes:before {
  content: "\e9db";
}
.icon-sunrise:before {
  content: "\e9dc";
}
.icon-snorkel:before {
  content: "\e9dd";
}
.icon-compass:before {
  content: "\e9de";
}
.icon-group:before {
  content: "\e9df";
}
.icon-humidity:before {
  content: "\e9e0";
}
.icon-mingroup:before {
  content: "\e9e1";
}
.icon-shopping-bag:before {
  content: "\e9e2";
}
.icon-thermometer:before {
  content: "\e9e3";
}
.icon-Aventure:before {
  content: "\e90d";
}
.icon-Cycling:before {
  content: "\e90e";
}
.icon-Distance:before {
  content: "\e90f";
}
.icon-Family:before {
  content: "\e910";
}
.icon-Hiking:before {
  content: "\e911";
}
.icon-Polar:before {
  content: "\e912";
}
.icon-Sailing:before {
  content: "\e913";
}
.icon-Walking:before {
  content: "\e914";
}
.icon-Wildlife:before {
  content: "\e915";
}
.icon-home2:before {
  content: "\e919";
}
.icon-office:before {
  content: "\e96a";
}
.icon-pencil:before {
  content: "\e942";
}
.icon-pencil6:before {
  content: "\e97d";
}
.icon-paint-format:before {
  content: "\e91a";
}
.icon-images:before {
  content: "\e91b";
}
.icon-image:before {
  content: "\e91c";
}
.icon-image4:before {
  content: "\e91d";
}
.icon-camera:before {
  content: "\e91e";
}
.icon-camera2:before {
  content: "\e91f";
}
.icon-camera3:before {
  content: "\e920";
}
.icon-play:before {
  content: "\e921";
}
.icon-reading:before {
  content: "\e97e";
}
.icon-file-text:before {
  content: "\e922";
}
.icon-profile:before {
  content: "\e923";
}
.icon-file-plus:before {
  content: "\e987";
}
.icon-file-minus:before {
  content: "\e988";
}
.icon-file-download:before {
  content: "\e989";
}
.icon-file-download2:before {
  content: "\e98a";
}
.icon-file-upload:before {
  content: "\e98b";
}
.icon-file-upload2:before {
  content: "\e98c";
}
.icon-file-check:before {
  content: "\e98d";
}
.icon-folder6:before {
  content: "\e924";
}
.icon-certificate:before {
  content: "\e925";
}
.icon-price-tag4:before {
  content: "\e926";
}
.icon-price-tags3:before {
  content: "\e927";
}
.icon-barcode:before {
  content: "\e928";
}
.icon-qrcode:before {
  content: "\e929";
}
.icon-ticket:before {
  content: "\e92a";
}
.icon-ticket2:before {
  content: "\e92b";
}
.icon-store:before {
  content: "\e92c";
}
.icon-store2:before {
  content: "\e92d";
}
.icon-cart2:before {
  content: "\e92e";
}
.icon-cart3:before {
  content: "\e92f";
}
.icon-percent:before {
  content: "\e930";
}
.icon-at-sign:before {
  content: "\e98e";
}
.icon-envelop:before {
  content: "\e900";
}
.icon-envelop2:before {
  content: "\e901";
}
.icon-compass3:before {
  content: "\e902";
}
.icon-calendar2:before {
  content: "\e918";
}
.icon-printer2:before {
  content: "\e98f";
}
.icon-printer3:before {
  content: "\e990";
}
.icon-display:before {
  content: "\e991";
}
.icon-users:before {
  content: "\e97f";
}
.icon-user-plus:before {
  content: "\e980";
}
.icon-user-minus:before {
  content: "\e981";
}
.icon-user-cancel:before {
  content: "\e982";
}
.icon-user-block:before {
  content: "\e983";
}
.icon-user-lock:before {
  content: "\e984";
}
.icon-user-check:before {
  content: "\e985";
}
.icon-users2:before {
  content: "\e986";
}
.icon-user3:before {
  content: "\e931";
}
.icon-user4:before {
  content: "\e932";
}
.icon-user5:before {
  content: "\e933";
}
.icon-users4:before {
  content: "\e96b";
}
.icon-collaboration:before {
  content: "\e992";
}
.icon-vcard:before {
  content: "\e993";
}
.icon-hat:before {
  content: "\e994";
}
.icon-tie:before {
  content: "\e995";
}
.icon-key5:before {
  content: "\e96c";
}
.icon-keyhole:before {
  content: "\e96d";
}
.icon-lock2:before {
  content: "\e96e";
}
.icon-wrench3:before {
  content: "\e96f";
}
.icon-equalizer:before {
  content: "\e970";
}
.icon-hammer-wrench:before {
  content: "\e971";
}
.icon-cake:before {
  content: "\e934";
}
.icon-medal-star:before {
  content: "\e935";
}
.icon-medal:before {
  content: "\e936";
}
.icon-medal2:before {
  content: "\e937";
}
.icon-mustache:before {
  content: "\e996";
}
.icon-spoon-knife:before {
  content: "\e938";
}
.icon-cook:before {
  content: "\e939";
}
.icon-coffee-bean:before {
  content: "\e93a";
}
.icon-coffee:before {
  content: "\e93b";
}
.icon-leaf:before {
  content: "\e93c";
}
.icon-apple:before {
  content: "\e93d";
}
.icon-grapes:before {
  content: "\e93e";
}
.icon-tree:before {
  content: "\e93f";
}
.icon-tree2:before {
  content: "\e940";
}
.icon-mountains:before {
  content: "\e903";
}
.icon-paw:before {
  content: "\e941";
}
.icon-flower:before {
  content: "\e943";
}
.icon-flower2:before {
  content: "\e944";
}
.icon-rocket:before {
  content: "\e945";
}
.icon-meter3:before {
  content: "\e946";
}
.icon-scale:before {
  content: "\e947";
}
.icon-bomb:before {
  content: "\e948";
}
.icon-lab:before {
  content: "\e949";
}
.icon-atom2:before {
  content: "\e94a";
}
.icon-lamp4:before {
  content: "\e972";
}
.icon-lamp5:before {
  content: "\e973";
}
.icon-lamp6:before {
  content: "\e974";
}
.icon-toiletpaper:before {
  content: "\e975";
}
.icon-bin:before {
  content: "\e976";
}
.icon-bin3:before {
  content: "\e997";
}
.icon-bin4:before {
  content: "\e998";
}
.icon-bin5:before {
  content: "\e999";
}
.icon-briefcase:before {
  content: "\e977";
}
.icon-briefcase3:before {
  content: "\e978";
}
.icon-paperplane:before {
  content: "\e94b";
}
.icon-car:before {
  content: "\e94c";
}
.icon-car2:before {
  content: "\e94d";
}
.icon-gas:before {
  content: "\e94e";
}
.icon-bus:before {
  content: "\e94f";
}
.icon-cube:before {
  content: "\e950";
}
.icon-cube3:before {
  content: "\e951";
}
.icon-cube4:before {
  content: "\e952";
}
.icon-package:before {
  content: "\e953";
}
.icon-glasses2:before {
  content: "\e954";
}
.icon-brain:before {
  content: "\e955";
}
.icon-accessibility:before {
  content: "\e956";
}
.icon-accessibility2:before {
  content: "\e957";
}
.icon-strategy:before {
  content: "\e958";
}
.icon-target3:before {
  content: "\e959";
}
.icon-shield:before {
  content: "\e979";
}
.icon-bow:before {
  content: "\e95a";
}
.icon-sword:before {
  content: "\e95b";
}
.icon-tennis:before {
  content: "\e95c";
}
.icon-pingpong:before {
  content: "\e95d";
}
.icon-switch:before {
  content: "\e95e";
}
.icon-power-cord2:before {
  content: "\e95f";
}
.icon-outlet:before {
  content: "\e960";
}
.icon-clipboard2:before {
  content: "\e961";
}
.icon-clipboard3:before {
  content: "\e962";
}
.icon-clipboard5:before {
  content: "\e963";
}
.icon-lan:before {
  content: "\e99a";
}
.icon-lan2:before {
  content: "\e99b";
}
.icon-lan3:before {
  content: "\e99c";
}
.icon-bed:before {
  content: "\e97a";
}
.icon-furniture:before {
  content: "\e97b";
}
.icon-chair:before {
  content: "\e97c";
}
.icon-star-empty3:before {
  content: "\e964";
}
.icon-star-half:before {
  content: "\e965";
}
.icon-star-full2:before {
  content: "\e966";
}
.icon-heart6:before {
  content: "\e967";
}
.icon-heart-broken2:before {
  content: "\e968";
}
.icon-heart7:before {
  content: "\e969";
}
.icon-plus2:before {
  content: "\e99e";
}
.icon-minus2:before {
  content: "\e99f";
}
.icon-plus-circle:before {
  content: "\e9a0";
}
.icon-minus-circle:before {
  content: "\e9a1";
}
.icon-plus-circle2:before {
  content: "\e9a2";
}
.icon-minus-circle2:before {
  content: "\e9a3";
}
.icon-info:before {
  content: "\e9a4";
}
.icon-info2:before {
  content: "\e9a5";
}
.icon-cancel-circle:before {
  content: "\e9a6";
}
.icon-cancel-circle2:before {
  content: "\e9a7";
}
.icon-cross:before {
  content: "\e9a8";
}
.icon-cross3:before {
  content: "\e9a9";
}
.icon-checkmark:before {
  content: "\e9aa";
}
.icon-checkmark2:before {
  content: "\e9ab";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-arrow-up2:before {
  content: "\e905";
}
.icon-arrow-right:before {
  content: "\e906";
}
.icon-arrow-right2:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-down2:before {
  content: "\e909";
}
.icon-arrow-left:before {
  content: "\e90a";
}
.icon-arrow-left2:before {
  content: "\e90b";
}
.icon-campaignmonitor:before {
  content: "\e90c";
  color: #509cf6;
}
.icon-phone:before {
  content: "\e9e4";
}
.icon-bug:before {
  content: "\e99d";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-google-plus:before {
  content: "\ea8b";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-rss:before {
  content: "\ea9b";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-linkedin:before {
  content: "\eaca";
}
.icon-lmcc:before {
  content: "\e916";
}
.icon-lmcc-logo:before {
  content: "\e917";
}
