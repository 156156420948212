@import '~variables';

.options {
  &__container {
    float: left;

    &.single {
      float: none;

      .options__list {
        padding: 0;
      }
    }

    &.invert {
      .exact {
        .options__btn,
        .options__btn.active {
          background: none;
          color: $white;

          &:hover,
          &.exact__active {
            background: $white;
            color: nth($grayscale, 5)
          }
        }
      }
    }
  }

  &__list {
    overflow: hidden;
    padding-top: 1.1rem;
  }

  &__item {
    float: left;
    padding-right: 10px;

    &.exact {
      .options__btn,
      .options__btn.active {
        background: none;
        color: nth($grayscale, 5);

        &:hover,
        &.exact__active {
          &:before {
            background: nth($grayscale, 5);
            color: $white;
          }
        }
      }
    }
  }

  &__btn {
    display: inline-block;
    text-align: center;
    color: nth($grayscale, 5);
    overflow: hidden;
    line-height: 2rem;
    text-transform: capitalize;
    padding: 0 20px 0 0;

    &:before {
      border-radius: 50%;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      margin: 0 10px 0 0;
      text-align: center;
      line-height: 2rem;
      font-size: 1.1rem;
    }

    &.exact,
    &:hover {
      &:before {
        background: nth($grayscale, 5);
        color: $white;
      }
    }
  }
}
