@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal) {
  @font-face {
    font-family: $font-family;
    src:  url('#{$file-path}.eot');
    src:  url('#{$file-path}.eot') format('embedded-opentype'),
      url('#{$file-path}.ttf') format('truetype'),
      url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.svg#icons') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}

@mixin ellipsis($lines: 1) {
  overflow : hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}
