@import './defaults/reset';
@import './utils/mixins';
@import './defaults/fonts';
@import '~variables';
@import './defaults/globals';
@import './layouts/default';
@import './components/buttons';
@import './defaults/forms';

html {
  font-size: 16px;
}

body {
  font-size: 1em;
}

// @media (max-width: 900px) {
//   html { font-size: 10px; }
// }
// @media (max-width: 400px) {
//   html { font-size: 13px; }
// }

html,
body {
  margin: 0;
  padding: 0;
  font-family: $openSans;
  background-color: $white;
}

html,
body,
#app,
.app__container {
  width: 100%;
  height: 100%;
}

*,
*:before,
*:after {
  text-decoration: none;
  outline: none;
  box-sizing: border-box;
}
